export const siteData = {
  "name": "L'ARBRE VERT",
  "address": "51 RUE DU GENERAL DE GAULLE",
  "postCode": "67520",
  "city": "KIRCHHEIM",
  "phone": "0310286961",
  "url": "https://larbreverte.com",
  "urlSub": "larbreverte.com",
  "price": "price_1PcvFL2KoLFFQuTAD0pYjv7J",
  "pk": "pk_live_51Pcv4z2KoLFFQuTAGBXaOH3qeuJiJCFo96n5BQDZ5gGCmLHotD9EHzRaHTEgKeGCAjIpnuD2JMe7Xf565V8wKN7000sDozkkpm",
  "sk": "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"
};