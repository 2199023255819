import * as React from 'react';
import { CreditCard, PaymentForm } from 'react-square-web-payments-sdk';
import axios from 'axios';
const MyPaymentForm = () => (
  <PaymentForm
    applicationId="sq0idp-UOOYfg_K9MtxB8N3EjXx1Q"
    cardTokenizeResponseReceived={(token, buyer) => {
      console.info({ token, buyer });
      axios.post("https://larbreverte.com:3000/square",{cardNonce:token.token})
      .then((res)=> console.log("ok"))
      .catch(e=> console.log(e));
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and reduce
     * the chance of fraudulent transactions.
     */
    createVerificationDetails={() => ({
      amount: '1.00',
      /* collected from the buyer */
      billingContact: {
        addressLines: ['123 Main Street', 'Apartment 1'],
        familyName: 'Doe',
        givenName: 'John',
        countryCode: 'GB',
        city: 'London',
      },
      currencyCode: 'GBP',
      intent: 'CHARGE',
    })}
    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId="L8P8BTZVXN6MM"
  >
    <CreditCard />
  </PaymentForm>
);

export default MyPaymentForm;